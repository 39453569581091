<template>
  <div>
    <span class="back" @click="$router.go(-1)">
      <font-awesome-icon icon="chevron-left" /> Volver
    </span>
    <div class="option-container">
      <step :step="3" />
      <loading :active.sync="loading" :is-full-page="true"></loading>
      <form class="client-form">
        <!-- <client-form v-if="client.rut" /> -->
        <div class="form-row">
          <div class="form-group col-10 col-md-10 pl-4" :class="{ 'has-error': !validate() }">
            <label>RUT</label>
            <input v-model="form.rut" class="form-control" @keyup="rutFormat" placeholder="Ingrese su rut" />
          </div>
          <div class="col-1">
            <div class="rut-search">
              <button type="button" class="btn btn-success btn-search" @click="findClient()" :disabled="!validate()">
                Buscar
              </button>
            </div>
          </div>
        </div>
        <client-form v-if="showRutForm"/>
      </form>
    </div>
  </div>
</template>

<script>
import ClientForm from "@/components/ClientForm";
import { mapGetters } from "vuex";
import Api from "@/api";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Step from "@/components/Step";
const api = new Api();
export default {
  name: "Client",
  components: { ClientForm, Loading, Step },
  data() {
    return {
      loading: false,
      showRutForm: false,
      form: {
        rut: "",
      },
    };
  },

  created() {
    if (!this.$store.state.scheduleAvailable) {
      this.$router.push("/atencion-veterinaria");
    }
    this.setClientData();
  },
  computed: {
    ...mapGetters(["client"]),
  },
  methods: {
    setClientData() {
      let client = {
        rut: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        reason: "",
        pet_id: "",
        pets: [],
        petName: "",
        petType: "",
      };
      this.$store.commit("setClient", client);
    },

    findClient() {
      this.loading = true;
      api.findClientByRutSimple(this.form.rut).then((response) => {
        let client = response.data.data;
        this.showRutForm = true;
        if (!client) {
          client = {
            rut: this.form.rut,
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            reason: "",
            pet_id: "",
            pets: [],
            petName: "",
            registro: "Cliente",
            nivel: "",
            petType: "",
          };
        } else {
          client.pet_id = "";
          client.petName = "";
          client.petType = "";
          client.reason = "";
          client.phone = "";
          client.email ="";
          client.registro= "Cliente",
          client.nivel= ""
        }
        this.$store.commit("setClient", client);
        this.loading = false;
      });
    },
    cleanRut() {
      return typeof this.form.rut === "string"
        ? this.form.rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
        : "";
    },

    validate() {
      let rut = this.form.rut;
      if (typeof rut !== "string") {
        return false;
      }
      if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(this.form.rut)) {
        return false;
      }

      rut = this.cleanRut();

      var t = parseInt(rut.slice(0, -1), 10);
      var m = 0;
      var s = 1;

      while (t > 0) {
        s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
        t = Math.floor(t / 10);
      }

      var v = s > 0 ? "" + (s - 1) : "K";
      return v === rut.slice(-1);
    },

    rutFormat() {
      const rut = this.cleanRut();
      let result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
      for (var i = 4; i < rut.length; i += 3) {
        result = rut.slice(-3 - i, -i) + "." + result;
      }
      this.form.rut = result;
    },

    getDigit(rut = this.form.rut) {
      rut = rut + "";
      // type check
      if (!rut || !rut.length || typeof rut !== "string") {
        return -1;
      }
      // serie numerica
      const secuencia = [2, 3, 4, 5, 6, 7, 2, 3];
      let sum = 0;
      //
      for (let i = rut.length - 1; i >= 0; i--) {
        const d = rut.charAt(i);
        sum += new Number(d) * secuencia[rut.length - (i + 1)];
      }
      // sum mod 11
      let rest = 11 - (sum % 11);
      // si es 11, retorna 0, sino si es 10 retorna K,
      // en caso contrario retorna el numero
      return rest === 11 ? 0 : rest === 10 ? "K" : rest;
    },
  },
};
</script>

<style scoped>
.rut-search {
  margin-top: 53%;
}
.has-error {
  color: red;
}
.has-error > input {
  border-color: red;
}
.client-form {
  position: relative;
  left: 3%;
}
.title {
  margin-top: 5%;
}
.option-container {
  position: relative;
}
.back {
  top: 3% !important;
}
.form-group > label {
  font-weight: 700;
}

@media (max-width: 425px) {
  .rut-search {
    margin-top: 35px;
  }
  .btn-search {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
}
</style>
