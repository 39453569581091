<template>
  <div class="modal fade" id="modalInfo" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Confirme los datos de su cita</h5>
          <button type="button" class="close" @click="close()" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="row">
                <div class="col-6 pl-0 align-self-center text-left">
                  <b>Fecha</b>
                </div>
                <div class="col-6 pr-0 align-self-center text-right">
                  {{ date.day }}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-6 pl-0 align-self-center text-left">
                  <b>Hora</b>
                </div>
                <div class="col-6 pr-0 align-self-center text-right">
                  {{ date.hour }}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-6 pl-0 align-self-center text-left">
                  <b>Motivo</b>
                </div>
                <div class="col-6 pr-0 align-self-center text-right">
                  {{ date.reason }}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-6 pl-0 align-self-center text-left">
                  <b>Veterinario</b>
                </div>
                <div class="col-6 pr-0 align-self-center text-right">
                  {{ date.veterinary }}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-6 pl-0 align-self-center text-left">
                  <b>Sucursal</b>
                </div>
                <div class="col-6 pr-0 align-self-center text-right">
                  {{ date.office.name }}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-6 pl-0 align-self-center text-left">
                  <b>Dirección De la sucursal</b>
                </div>
                <div class="col-6 pr-0 align-self-center text-right">{{ date.office.address }}</div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-6 pl-0 align-self-center text-left">
                  <b>Teléfono de la sucursal</b>
                </div>
                <div class="col-6 pr-0 align-self-center text-right">{{ date.office.phone }}</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close()" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" @click="confirm()" data-dismiss="modal">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import $ from "jquery";

export default {
  name: "DialgConfirm",
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    dialogVisible() {
      if (this.dialogVisible) {
        $("#modalInfo").modal("show");
      }
    },
  },
  computed: {
    ...mapGetters(["date"]),
  },
  methods: {
    confirm() {
      this.$emit("save");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.text-left {
  text-align: left !important;
}
.list-group-flush {
  border-radius: 0;
}
.text-right {
  text-align: right !important;
}
.align-self-center {
  align-self: center !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

@media (max-width: 425px) {
  .modal-dialog {
    margin-right: 15px;
  }
}
</style>
