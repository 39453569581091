<template>
  <div>
    <loading :active.sync="loading" :is-full-page="true"></loading>
    <div class="client-form">
      <form :model="client">
        <div
          class="form-row"
          :class="{ 'has-error': $v.client.first_name.$error }"
        >
          <div class="form-group col-11 col-md-11">
            <label>Nombre</label>
            <input
              v-model="$v.client.first_name.$model"
              class="form-control"
              :class="{ 'error-danger': $v.client.first_name.$error }"
              placeholder="Nombre de cliente"
            />
            <span v-if="$v.client.first_name.$error" class="help-block"
              >Por favor ingrese su nombre</span
            >
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-11 col-md-11">
            <label>Apellido</label>
            <input
              v-model="client.last_name"
              class="form-control"
              placeholder="Apellido de cliente"
            />
          </div>
        </div>
        <div class="form-row" :class="{ 'has-error': $v.client.phone.$error }">
          <div class="form-group col-11 col-md-11">
            <label>Teléfono</label>
            <input
              v-model="$v.client.phone.$model"
              class="form-control"
              :class="{ 'error-danger': $v.client.phone.$error }"
              placeholder="Teléfono"
            />
            <span v-if="$v.client.phone.$error" class="help-block"
              >Por favor ingrese un número de teléfono</span
            >
          </div>
        </div>

        <div class="form-row" :class="{ 'has-error': $v.client.email.$error }">
          <div class="form-group col-11 col-md-11">
            <label>Email</label>
            <input
              v-model="$v.client.email.$model"
              :class="{ 'error-danger': $v.client.email.$error }"
              class="form-control"
              placeholder="Email"
            />
            <span v-if="$v.client.email.$error" class="help-block"
              >Por favor, ingrese un email válido</span
            >
          </div>
        </div>
        <div class="form-row" :class="{ 'has-error': $v.client.reason.$error }">
          <div class="form-group col-11 col-md-11">
            <label>Motivo de la consulta</label>
            <select
              v-model="$v.client.reason.$model"
              :class="{ 'error-danger': $v.client.reason.$error }"
              placeholder="Motivo de la consulta"
              class="form-control"
            >
              <option
                label="Seleccione el motivo de la consulta"
                selected
                disabled
              />
              <option
                v-for="item in reasons"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              />
            </select>
            <span v-if="$v.client.reason.$error" class="help-block"
              >Por favor seleccione un motivo de consulta</span
            >
          </div>
        </div>
        <div class="form-row" v-if="client.pets.length >= 1 && !newPet">
          <div
            class="form-group col-11 col-md-11"
            :class="{ 'has-error': $v.client.pet_id.$error }"
          >
            <label>Mascota</label>
            <p>***Si su mascota no es perro ni gato, por favor agende su hora directamente en su local más cercano.</p>
            <select
              v-model="$v.client.pet_id.$model"
              :class="{ 'error-danger': $v.client.pet_id.$error }"
              class="form-control"
              placeholder="Selecciona una mascota"
            >
              <option
                v-for="item in client.pets"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </select>
            <span v-if="$v.client.petType.$error" class="help-block"
              >Por favor seleccione una mascota</span
            >
            <span class="new-pet" @click="newPet = true"
              >+ Agregar Nueva mascota</span
            >
          </div>
        </div>

        <template v-else-if="client.pets.length == 0 || newPet">
          <div
            class="form-row"
            :class="{ 'has-error': $v.client.petType.$error }"
          >
            <div class="form-group col-11 col-md-11">
              <label>Tipo de la mascota</label>
              <p>***Si su mascota no es perro ni gato, por favor agende su hora directamente en su local más cercano.</p>
              <select
                v-model="$v.client.petType.$model"
                class="form-control"
                :class="{ 'error-danger': $v.client.petType.$error }"
                placeholder="selecciona un tipo"
              >
                <option
                  label="Seleccione el tipo de la mascota"
                  selected
                  disabled
                />
                <option
                  v-for="item in petTypes"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </select>
              <span v-if="$v.client.petType.$error" class="help-block"
                >Por favor seleccione el tipo de mascota</span
              >
            </div>
          </div>

          <div
            class="form-row"
            :class="{ 'has-error': $v.client.petName.$error }"
          >
            <div class="form-group col-11 col-md-11">
              <label>Nombre de la mascota</label>
              <input
                v-model="$v.client.petName.$model"
                :class="{ 'error-danger': $v.client.petName.$error }"
                class="form-control"
                placeholder="Nombre de la mascota"
              />

              <span v-if="$v.client.petName.$error" class="help-block"
                >Por favor ingrese el nombre de la mascota</span
              >
              <span
                v-if="this.client.id"
                class="new-pet quit-pet"
                @click="newPet = false"
                >Quitar</span
              >
            </div>
          </div>
        </template>
        <div class="form-row">
          <div class="form-group col-11 col-md-11">
            <button
              type="button"
              class="btn btn-success btn-save"
              @click="save()"
            >
              Guardar
            </button>
          </div>
        </div>
      </form>
      <dialog-confirm
        :dialog-visible="dialogVisible"
        @save="confirmSave"
        @close="dialogVisible = false"
      />
    </div>
  </div>
</template>

<script>
import Api from "@/api";
import DialogConfirm from "@/components/DialogConfirm";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { required, email, requiredIf } from "vuelidate/lib/validators";
const api = new Api();
export default {
  name: "ClientForm",
  components: { DialogConfirm, Loading },

  validations: {
    client: {
      first_name: {
        required,
      },

      email: { required, email },

      phone: {
        required,
      },
      rut: {
      required,
      },
      reason: {
        required,
      },
      petName: {
        required: requiredIf(function () {
          return !(this.client && this.client.id) || this.newPet;
        }),
      },
      petType: {
        required: requiredIf(function () {
          return !this.client.id || this.newPet;
        }),
      },
      pet_id: {
        required: requiredIf(function () {
          return this.client.id && !this.newPet;
        }),
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      newPet: false,
      pet: {
        type_id: null,
        name: "",
      },
      form: {
        rut: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      client: "client",
      reasons: "reasons",
      petTypes: "species",
    }),
  },

  methods: {
    save() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return false;
      }

      this.$store.commit("setDate", {
        reason: this.client.reason,
        veterinary: this.$store.state.scheduleAvailable.veterinary.name,
        office: this.$store.state.scheduleAvailable.office,
        hour: this.$store.state.scheduleAvailable.hour.start,
        day: this.$store.state.scheduleAvailable.day,
      });
      this.dialogVisible = true;
    },

    async confirmSave() {
      this.dialogVisible = false;
      this.loading = true;
      const data = {
        clientId: this.client.id,
        specialtyId:  this.$store.state.queryForm.specialty_id,
        registro: this.client.registro,
        clientName: this.client.first_name,
        clientLastName: this.client.last_name,
        clientEmail: this.client.email,
        clientPhone: this.client.phone,
        pet: this.newPet ? null : this.client.pet_id,
        reason: this.client.reason,
        clientRut: this.client.rut,
        petName: this.client.petName,
        specieId: this.client.petType,
        scheduleAvailableId: this.$store.state.scheduleAvailable.hour.id,
        veterinaryId: this.$store.state.scheduleAvailable.veterinary.id,
        sucursalId: this.$store.state.scheduleAvailable.office.id,
        hour: this.$store.state.scheduleAvailable.hour.start,
        day: this.$store.state.scheduleAvailable.day,
      };
      const response = await api.save(data);
      if (response.status == 200) {
        const data = await response.data;
        if(!data.error){
        this.loading = false;
        this.$store.commit("setDate", {
          code: data.schedule.id,
          reason: this.client.reason,
          veterinary: this.$store.state.scheduleAvailable.veterinary.name,
          office: this.$store.state.scheduleAvailable.office,
          hour: this.$store.state.scheduleAvailable.hour.start,
          day: this.$store.state.scheduleAvailable.day,
          });
        this.$router.push("/end-flow");
        }
        else{
        this.$router.push("/appointment-error");
        }
      
      }
      this.loading = false;
    },

    cleanRut() {
      return typeof this.form.rut === "string"
        ? this.form.rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
        : "";
    },

    validate() {
      let rut = this.form.rut;
      if (typeof rut !== "string") {
        return false;
      }
      if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(this.form.rut)) {
        return false;
      }

      rut = this.cleanRut();

      var t = parseInt(rut.slice(0, -1), 10);
      var m = 0;
      var s = 1;

      while (t > 0) {
        s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
        t = Math.floor(t / 10);
      }

      var v = s > 0 ? "" + (s - 1) : "K";
      return v === rut.slice(-1);
    },

    rutFormat() {
      const rut = this.cleanRut();
      let result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
      for (var i = 4; i < rut.length; i += 3) {
        result = rut.slice(-3 - i, -i) + "." + result;
      }
      this.form.rut = result;
    },

    getDigit(rut = this.form.rut) {
      rut = rut + "";
      // type check
      if (!rut || !rut.length || typeof rut !== "string") {
        return -1;
      }
      // serie numerica
      const secuencia = [2, 3, 4, 5, 6, 7, 2, 3];
      let sum = 0;
      //
      for (let i = rut.length - 1; i >= 0; i--) {
        const d = rut.charAt(i);
        sum += new Number(d) * secuencia[rut.length - (i + 1)];
      }
      // sum mod 11
      let rest = 11 - (sum % 11);
      // si es 11, retorna 0, sino si es 10 retorna K,
      // en caso contrario retorna el numero
      return rest === 11 ? 0 : rest === 10 ? "K" : rest;
    },
  },
};
</script>

<style scoped>
.client-form {
  position: relative;
  text-align: left !important;
  padding-bottom: 3%;
}
.form {
  width: 95%;
  margin-top: 0%;
}
.select {
  width: 100%;
}
.form-group > label {
  font-weight: 700;
}
.new-pet {
  position: relative;
  color: green;
  left: 72%;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}
.quit-pet {
  left: 93%;
}
.btn-save {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  font-weight: 700;
}
.has-error {
  color: red;
}
.help-block {
  font-weight: 800;
  font-size: 12px;
}
.error-danger {
  color: red;
  border-color: #c51023;
}

.rut-search {
  margin-top: 53%;
}
.has-error {
  color: red;
}
.has-error > input {
  border-color: red;
}
.client-form {
  position: relative;
  left: 3%;
}
.title {
  margin-top: 5%;
}
.option-container {
  position: relative;
}
.back {
  top: 3% !important;
}
.form-group > label {
  font-weight: 700;
}
@media (max-width: 425px) {
  .rut-search {
    margin-top: 35px;
  }
  .btn-search {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  .new-pet {
    left: 39%;
  }
  .quit-pet {
    left: 84%;
  }
}
</style>
